"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorHandler = exports.ChainAdapterError = void 0;
class ChainAdapterError extends Error {
    constructor(error, metadata) {
        if (typeof error === 'string') {
            super(error);
        }
        else if (error instanceof Error || error instanceof ChainAdapterError) {
            super(error.message);
        }
        else {
            super(`Unknown Error: ${error}`);
        }
        this.name = this.constructor.name;
        this.metadata = error.metadata ?? metadata;
        Error.captureStackTrace(this, this.constructor);
    }
}
exports.ChainAdapterError = ChainAdapterError;
const ErrorHandler = async (err, metadata) => {
    if (err.isAxiosError) {
        const response = JSON.stringify(err.response?.data);
        if (metadata)
            throw new ChainAdapterError(response, metadata);
        throw new Error(response);
    }
    else if (err.name === 'ResponseError') {
        // handle fetch api error coming from generated typescript client
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response = JSON.stringify(await err.response.json());
        if (metadata)
            throw new ChainAdapterError(response, metadata);
        throw new Error(response);
    }
    else if (err instanceof Error || err instanceof ChainAdapterError) {
        if (metadata)
            throw new ChainAdapterError(err, metadata);
        throw err;
    }
    else if (typeof err == 'string') {
        if (metadata)
            throw new ChainAdapterError(err, metadata);
        throw new Error(err);
    }
    if (metadata)
        throw new ChainAdapterError(err, metadata);
    throw new Error(JSON.stringify(err));
};
exports.ErrorHandler = ErrorHandler;
