"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNativeFeeAssetReference = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const getNativeFeeAssetReference = (chainNamespace, chainReference) => {
    return (() => {
        switch (chainNamespace) {
            case caip_1.CHAIN_NAMESPACE.Utxo:
                switch (chainReference) {
                    case caip_1.CHAIN_REFERENCE.BitcoinMainnet:
                        return caip_1.ASSET_REFERENCE.Bitcoin;
                    case caip_1.CHAIN_REFERENCE.BitcoinCashMainnet:
                        return caip_1.ASSET_REFERENCE.BitcoinCash;
                    case caip_1.CHAIN_REFERENCE.DogecoinMainnet:
                        return caip_1.ASSET_REFERENCE.Dogecoin;
                    case caip_1.CHAIN_REFERENCE.LitecoinMainnet:
                        return caip_1.ASSET_REFERENCE.Litecoin;
                    default:
                        throw new Error(`Chain namespace ${chainNamespace} on ${chainReference} not supported.`);
                }
            case caip_1.CHAIN_NAMESPACE.Evm:
                switch (chainReference) {
                    case caip_1.CHAIN_REFERENCE.AvalancheCChain:
                        return caip_1.ASSET_REFERENCE.AvalancheC;
                    case caip_1.CHAIN_REFERENCE.EthereumMainnet:
                        return caip_1.ASSET_REFERENCE.Ethereum;
                    case caip_1.CHAIN_REFERENCE.OptimismMainnet:
                        return caip_1.ASSET_REFERENCE.Optimism;
                    case caip_1.CHAIN_REFERENCE.BnbSmartChainMainnet:
                        return caip_1.ASSET_REFERENCE.BnbSmartChain;
                    case caip_1.CHAIN_REFERENCE.PolygonMainnet:
                        return caip_1.ASSET_REFERENCE.Polygon;
                    case caip_1.CHAIN_REFERENCE.GnosisMainnet:
                        return caip_1.ASSET_REFERENCE.Gnosis;
                    case caip_1.CHAIN_REFERENCE.ArbitrumMainnet:
                        return caip_1.ASSET_REFERENCE.Arbitrum;
                    case caip_1.CHAIN_REFERENCE.ArbitrumNovaMainnet:
                        return caip_1.ASSET_REFERENCE.ArbitrumNova;
                    case caip_1.CHAIN_REFERENCE.BaseMainnet:
                        return caip_1.ASSET_REFERENCE.Base;
                    default:
                        throw new Error(`Chain namespace ${chainNamespace} on ${chainReference} not supported.`);
                }
            case caip_1.CHAIN_NAMESPACE.CosmosSdk:
                switch (chainReference) {
                    case caip_1.CHAIN_REFERENCE.CosmosHubMainnet:
                        return caip_1.ASSET_REFERENCE.Cosmos;
                    case caip_1.CHAIN_REFERENCE.ThorchainMainnet:
                        return caip_1.ASSET_REFERENCE.Thorchain;
                    default:
                        throw new Error(`Chain namespace ${chainNamespace} on ${chainReference} not supported.`);
                }
            case caip_1.CHAIN_NAMESPACE.Solana:
                switch (chainReference) {
                    case caip_1.CHAIN_REFERENCE.SolanaMainnet:
                        return caip_1.ASSET_REFERENCE.Solana;
                    default:
                        throw new Error(`Chain namespace ${chainNamespace} on ${chainReference} not supported.`);
                }
            default:
                throw new Error(`Chain namespace ${chainNamespace} on ${chainReference} not supported.`);
        }
    })();
};
exports.getNativeFeeAssetReference = getNativeFeeAssetReference;
