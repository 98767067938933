"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getValuesFromQuoteResponse = exports.deductSlippageFromAmount = exports.deductAffiliateFeesFromAmount = exports.assertValidTrade = exports.getNowPlusThirtyMinutesTimestamp = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const types_1 = require("../../../../types");
const utils_2 = require("../../../../utils");
const getNowPlusThirtyMinutesTimestamp = () => {
    const ts = new Date();
    ts.setMinutes(ts.getMinutes() + 30);
    return Math.round(ts.getTime() / 1000);
};
exports.getNowPlusThirtyMinutesTimestamp = getNowPlusThirtyMinutesTimestamp;
const assertValidTrade = ({ buyAsset, sellAsset, supportedChainIds, }) => {
    if (!supportedChainIds.includes(sellAsset.chainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `[CowSwap: assertValidTrade] - unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (sellAsset.chainId !== buyAsset.chainId) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `[CowSwap: assertValidTrade] - both assets must be on chainId ${sellAsset.chainId}`,
            code: types_1.TradeQuoteError.CrossChainNotSupported,
            details: { buyAsset, sellAsset },
        }));
    }
    if ((0, caip_1.fromAssetId)(sellAsset.assetId).assetNamespace !== 'erc20') {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: '[CowSwap: assertValidTrade] - Sell asset must be an ERC-20',
            code: types_1.TradeQuoteError.UnsupportedTradePair,
            details: { sellAsset },
        }));
    }
    return (0, monads_1.Ok)(true);
};
exports.assertValidTrade = assertValidTrade;
const deductAffiliateFeesFromAmount = ({ amount, affiliateBps, }) => {
    const hasAffiliateFee = (0, utils_1.bnOrZero)(affiliateBps).gt(0);
    if (!hasAffiliateFee)
        return amount;
    return (0, utils_1.bn)(amount)
        .times((0, utils_1.bn)(1).minus((0, utils_1.convertBasisPointsToDecimalPercentage)(affiliateBps)))
        .toFixed(0);
};
exports.deductAffiliateFeesFromAmount = deductAffiliateFeesFromAmount;
const deductSlippageFromAmount = ({ amount, slippageTolerancePercentageDecimal, }) => {
    return (0, utils_1.bn)(amount).minus((0, utils_1.bn)(amount).times(slippageTolerancePercentageDecimal));
};
exports.deductSlippageFromAmount = deductSlippageFromAmount;
const getValuesFromQuoteResponse = ({ buyAsset, sellAsset, response, affiliateBps, }) => {
    const { sellAmount: sellAmountAfterFeesCryptoBaseUnit, feeAmount: feeAmountInSellTokenCryptoBaseUnit, buyAmount, } = response.quote;
    // Remove affiliate fees off the buyAmount to get the amount after affiliate fees, but before slippage bips
    const buyAmountAfterAffiliateFeesCryptoBaseUnit = (0, exports.deductAffiliateFeesFromAmount)({
        amount: buyAmount,
        affiliateBps,
    });
    const buyAmountAfterAffiliateFeesCryptoPrecision = (0, utils_1.fromBaseUnit)(buyAmountAfterAffiliateFeesCryptoBaseUnit, buyAsset.precision);
    const sellAmountCryptoPrecision = (0, utils_1.fromBaseUnit)(sellAmountAfterFeesCryptoBaseUnit, sellAsset.precision);
    const rate = (0, utils_1.bnOrZero)(buyAmountAfterAffiliateFeesCryptoPrecision)
        .div(sellAmountCryptoPrecision)
        .toString();
    const sellAmountBeforeFeesCryptoBaseUnit = (0, utils_1.bnOrZero)(sellAmountAfterFeesCryptoBaseUnit)
        .plus(feeAmountInSellTokenCryptoBaseUnit)
        .toFixed();
    const buyAmountBeforeAffiliateAndProtocolFeesCryptoBaseUnit = (0, utils_1.convertPrecision)({
        value: sellAmountBeforeFeesCryptoBaseUnit,
        inputExponent: sellAsset.precision,
        outputExponent: buyAsset.precision,
    })
        .times(rate)
        .toFixed(0);
    return {
        rate,
        buyAmountBeforeFeesCryptoBaseUnit: buyAmountBeforeAffiliateAndProtocolFeesCryptoBaseUnit,
        buyAmountAfterFeesCryptoBaseUnit: buyAmountAfterAffiliateFeesCryptoBaseUnit,
    };
};
exports.getValuesFromQuoteResponse = getValuesFromQuoteResponse;
