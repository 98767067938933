"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cowSwapper = void 0;
const types_1 = require("@shapeshiftoss/types");
const cowswap_utils_1 = require("../../cowswap-utils");
const filterAssetIdsBySellable_1 = require("./filterAssetIdsBySellable/filterAssetIdsBySellable");
const filterBuyAssetsBySellAssetId_1 = require("./filterBuyAssetsBySellAssetId/filterBuyAssetsBySellAssetId");
const cowService_1 = require("./utils/cowService");
exports.cowSwapper = {
    executeEvmMessage: async ({ chainId, orderToSign }, { signMessage }, config) => {
        // Removes the types that aren't part of GpV2Order types or structured signing will fail
        const { appDataHash, appData } = orderToSign;
        if (!appDataHash)
            throw Error('Missing appDataHash');
        const signature = await (0, cowswap_utils_1.signCowOrder)(orderToSign, chainId, signMessage);
        const network = (0, cowswap_utils_1.assertGetCowNetwork)(chainId);
        const maybeOrdersResponse = await cowService_1.cowService.post(`${config.REACT_APP_COWSWAP_BASE_URL}/${network}/api/v1/orders/`, {
            ...orderToSign,
            signingScheme: types_1.SigningScheme.EIP712,
            signature,
            appData,
            appDataHash,
        });
        if (maybeOrdersResponse.isErr())
            throw maybeOrdersResponse.unwrapErr();
        const { data: orderUid } = maybeOrdersResponse.unwrap();
        return orderUid;
    },
    filterAssetIdsBySellable: (assets) => {
        return Promise.resolve((0, filterAssetIdsBySellable_1.filterAssetIdsBySellable)(assets));
    },
    filterBuyAssetsBySellAssetId: (input) => {
        return Promise.resolve((0, filterBuyAssetsBySellAssetId_1.filterBuyAssetsBySellAssetId)(input));
    },
};
