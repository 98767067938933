"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterAssetIdsBySellable = void 0;
const constants_1 = require("../../../cowswap-utils/constants");
const helpers_1 = require("../../utils/helpers/helpers");
const blacklist_1 = require("../utils/blacklist");
const filterAssetIdsBySellable = (assets) => {
    return assets
        .filter(asset => {
        return (constants_1.SUPPORTED_CHAIN_IDS.includes(asset.chainId) &&
            !blacklist_1.COWSWAP_UNSUPPORTED_ASSETS.includes(asset.assetId) &&
            !(0, helpers_1.isNativeEvmAsset)(asset.assetId));
    })
        .map(asset => asset.assetId);
};
exports.filterAssetIdsBySellable = filterAssetIdsBySellable;
