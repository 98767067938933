"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseTransactionParser = void 0;
const bignumber_js_1 = require("bignumber.js");
const types_1 = require("../../types");
const utils_1 = require("../../utils");
const utils_2 = require("./utils");
__exportStar(require("./types"), exports);
class BaseTransactionParser {
    constructor(args) {
        this.parsers = [];
        this.chainId = args.chainId;
        this.assetId = args.assetId;
    }
    /**
     * Register custom transaction sub parser to parse custom op return data
     *
     * _parsers should be registered from most generic first to most specific last_
     */
    registerParser(parser) {
        this.parsers.unshift(parser);
    }
    registerParsers(parsers) {
        parsers.forEach(parser => this.registerParser(parser));
    }
    async parse(tx, address) {
        const parserResults = await (async () => {
            for (const parser of this.parsers) {
                const result = await parser.parse(tx, address);
                if (result)
                    return result;
            }
        })();
        const parsedTx = {
            address,
            blockHash: tx.blockHash,
            blockHeight: tx.blockHeight ?? -1,
            blockTime: tx.timestamp ?? Math.floor(Date.now() / 1000),
            chainId: this.chainId,
            confirmations: tx.confirmations,
            status: this.getStatus(tx),
            transfers: parserResults?.transfers ?? [],
            txid: tx.txid,
            trade: parserResults?.trade,
            data: parserResults?.data,
        };
        tx.messages.forEach((msg, i) => {
            const { from, to, value, origin } = msg;
            // We use origin for fees because some txs have a different from and origin addresses
            if (origin === address) {
                // network fee
                const fees = new bignumber_js_1.BigNumber(tx.fee.amount);
                if (fees.gt(0)) {
                    parsedTx.fee = { assetId: this.assetId, value: fees.toString(10) };
                }
            }
            const assetId = (0, utils_2.getAssetIdByDenom)(value?.denom, this.assetId);
            if (!assetId)
                return;
            // attempt to get transaction metadata from the raw messages and events if not already found by a subparser
            if (i === 0 && !parsedTx.data)
                parsedTx.data = (0, utils_2.metaData)(msg, tx.events[msg.index], assetId);
            const amount = new bignumber_js_1.BigNumber(value?.amount ?? -1);
            if (from === address && amount.gte(0)) {
                parsedTx.transfers = (0, utils_1.aggregateTransfer)({
                    assetId,
                    from,
                    to,
                    transfers: parsedTx.transfers,
                    type: types_1.TransferType.Send,
                    value: amount.toString(10),
                    allowZeroValue: true,
                });
            }
            if (to === address && amount.gt(0)) {
                parsedTx.transfers = (0, utils_1.aggregateTransfer)({
                    assetId,
                    from,
                    to,
                    transfers: parsedTx.transfers,
                    type: types_1.TransferType.Receive,
                    value: amount.toString(10),
                });
            }
            // special case for rune pool withdraw transfer (no message emitted so parsing of events for transfer is required)
            if (msg.type === 'deposit' && tx.events[msg.index]['rune_pool_withdraw']) {
                const transfer = tx.events[msg.index]['transfer'];
                const regex = /^(?<value>\d+)(?<denom>[a-zA-Z]+)$/;
                const match = transfer?.amount.match(regex);
                if (match?.groups) {
                    const { value } = match.groups;
                    parsedTx.transfers = (0, utils_1.aggregateTransfer)({
                        assetId,
                        from: transfer.sender,
                        to: transfer.recipient,
                        transfers: parsedTx.transfers,
                        type: types_1.TransferType.Receive,
                        value,
                    });
                }
            }
        });
        return Promise.resolve(parsedTx);
    }
    getStatus(tx) {
        if (tx.events['0']?.error)
            return types_1.TxStatus.Failed;
        if (tx.confirmations <= 0)
            return types_1.TxStatus.Pending;
        if (tx.confirmations > 0)
            return types_1.TxStatus.Confirmed;
        return types_1.TxStatus.Unknown;
    }
}
exports.BaseTransactionParser = BaseTransactionParser;
