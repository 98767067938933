"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterBuyAssetsBySellAssetId = void 0;
const constants_1 = require("../../../cowswap-utils/constants");
const helpers_1 = require("../../utils/helpers/helpers");
const blacklist_1 = require("../utils/blacklist");
const filterBuyAssetsBySellAssetId = ({ assets, sellAsset, }) => {
    if (sellAsset === undefined ||
        !constants_1.SUPPORTED_CHAIN_IDS.includes(sellAsset.chainId) ||
        (0, helpers_1.isNativeEvmAsset)(sellAsset.assetId) ||
        blacklist_1.COWSWAP_UNSUPPORTED_ASSETS.includes(sellAsset.assetId)) {
        return [];
    }
    return assets
        .filter(asset => {
        return (asset.assetId !== sellAsset.assetId &&
            sellAsset.chainId === asset.chainId &&
            !blacklist_1.COWSWAP_UNSUPPORTED_ASSETS.includes(asset.assetId));
    })
        .map(asset => asset.assetId);
};
exports.filterBuyAssetsBySellAssetId = filterBuyAssetsBySellAssetId;
