"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JUPITER_REFERALL_FEE_PROJECT_ACCOUNT = exports.JUPITER_AFFILIATE_CONTRACT_ADDRESS = exports.SHAPESHIFT_JUPITER_REFERRAL_KEY = exports.JUPITER_COMPUTE_UNIT_MARGIN_MULTIPLIER = exports.TOKEN_2022_PROGRAM_ID = exports.SOLANA_RANDOM_ADDRESS = exports.PDA_ACCOUNT_CREATION_COST = exports.JUPITER_SUPPORTED_CHAIN_IDS = exports.jupiterSupportedChainIds = void 0;
const types_1 = require("@shapeshiftoss/types");
exports.jupiterSupportedChainIds = [types_1.KnownChainIds.SolanaMainnet];
exports.JUPITER_SUPPORTED_CHAIN_IDS = {
    sell: exports.jupiterSupportedChainIds,
    buy: exports.jupiterSupportedChainIds,
};
exports.PDA_ACCOUNT_CREATION_COST = 2040000;
exports.SOLANA_RANDOM_ADDRESS = '2zHKF6tqam3tnNFPK2E9nBDkV7GMXnvdJautmzqQdn8A';
exports.TOKEN_2022_PROGRAM_ID = 'TokenzQdBNbLqP5VEhdkAS6EPFLC1PHnBqCXEpPxuEb';
// Jupiter use 40% as a compute unit margin while calculating them, some TX reverts without this
exports.JUPITER_COMPUTE_UNIT_MARGIN_MULTIPLIER = 1.4;
exports.SHAPESHIFT_JUPITER_REFERRAL_KEY = 'Ajgmo453yGmcHDPoJBrMUj3GFwLVL7HaaZGNLkB8vREG';
exports.JUPITER_AFFILIATE_CONTRACT_ADDRESS = 'REFER4ZgmyYx9c6He5XfaTMiGfdLwRnkV4RPp9t9iF3';
exports.JUPITER_REFERALL_FEE_PROJECT_ACCOUNT = '45ruCyfdRkWpRNGEqWzjCiXRHkZs8WXCLQ67Pnpye7Hp';
