"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTradeRate = void 0;
const getTradeQuote_1 = require("./getTradeQuote");
// This isn't a mistake. A trade rate *is* a trade quote. Chainflip doesn't really have a notion of a trade quote,
// they do have a notion of a "swap" (which we effectively only use to get the deposit address), which is irrelevant to the notion of quote vs. rate
const getTradeRate = async (input, deps) => {
    const rates = await (0, getTradeQuote_1._getTradeQuote)(input, deps);
    return rates;
};
exports.getTradeRate = getTradeRate;
