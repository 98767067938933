"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNetworkFeeCryptoBaseUnit = void 0;
const sdk_1 = require("@lifi/sdk");
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const contracts_1 = require("@shapeshiftoss/contracts");
const utils_1 = require("@shapeshiftoss/utils");
const viem_1 = require("viem");
const configureLiFi_1 = require("../configureLiFi");
const constants_1 = require("../constants");
const getNetworkFeeCryptoBaseUnit = async ({ chainId, from, lifiStep, supportsEIP1559, deps, }) => {
    (0, configureLiFi_1.configureLiFi)();
    const adapter = deps.assertGetEvmChainAdapter(chainId);
    const { average } = await adapter.getGasFeeData();
    try {
        if (!from)
            throw new Error('Cannot estimate fees without from');
        const { transactionRequest } = await (0, sdk_1.getStepTransaction)(lifiStep);
        if (!transactionRequest)
            throw new Error('transactionRequest is undefined');
        const { data, to, value } = transactionRequest;
        if (data === undefined)
            throw new Error('transactionRequest: data is required');
        if (to === undefined)
            throw new Error('transactionRequest: to is required');
        if (value === undefined)
            throw new Error('transactionRequest: value is required');
        // Attempt own fees estimation if possible (approval granted, wallet connected with from addy to use for simulation)
        const feeData = await chain_adapters_1.evm.getFees({
            adapter,
            data,
            to,
            // This looks odd but we need this, else unchained estimate calls will fail with:
            // "invalid decimal value (argument=\"value\", value=\"0x0\", code=INVALID_ARGUMENT, version=bignumber/5.7.0)"
            value: (0, utils_1.bn)(value.toString()).toString(),
            from,
            supportsEIP1559,
        });
        return feeData.networkFeeCryptoBaseUnit;
    }
    catch (err) {
        // Leverage Li.Fi (wrong) estimations if unable to roll our own
        const l1GasLimit = await (async () => {
            if (!constants_1.L1_FEE_CHAIN_IDS.includes(chainId))
                return;
            const { transactionRequest } = await (0, sdk_1.getStepTransaction)(lifiStep);
            const { data, gasLimit } = transactionRequest ?? {};
            if (!data || !gasLimit) {
                throw new Error('getStepTransaction failed');
            }
            const publicClient = contracts_1.viemClientByChainId[chainId];
            const abi = [
                {
                    inputs: [{ internalType: 'bytes', name: '_data', type: 'bytes' }],
                    name: 'getL1GasUsed',
                    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
                    stateMutability: 'view',
                    type: 'function',
                },
            ];
            const contract = (0, viem_1.getContract)({
                address: constants_1.L1_GAS_ORACLE_ADDRESS,
                abi,
                client: {
                    public: publicClient,
                },
            });
            const l1GasUsed = (await contract.read.getL1GasUsed([data]));
            return l1GasUsed.toString();
        })();
        // aggregate all send gas estimations if available
        const estimatedGasLimit = lifiStep.estimate.gasCosts?.reduce((prev, gasCost) => {
            if (gasCost.type !== 'SEND')
                return prev;
            if (prev === undefined)
                return BigInt(gasCost.estimate);
            return prev + BigInt(gasCost.estimate);
        }, undefined);
        if (!estimatedGasLimit)
            throw new Error('failed to get estimated gas limit');
        const networkFeeCryptoBaseUnit = chain_adapters_1.evm.calcNetworkFeeCryptoBaseUnit({
            ...average,
            supportsEIP1559,
            gasLimit: estimatedGasLimit.toString(),
            l1GasLimit,
        });
        return networkFeeCryptoBaseUnit;
    }
};
exports.getNetworkFeeCryptoBaseUnit = getNetworkFeeCryptoBaseUnit;
