"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTradeRate = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const uuid_1 = require("uuid");
const types_1 = require("../../../types");
const utils_2 = require("../../../utils");
const constants_1 = require("../utils/constants");
const helpers_1 = require("../utils/helpers");
const getTradeRate = async (input, deps) => {
    const { sellAsset, buyAsset, sellAmountIncludingProtocolFeesCryptoBaseUnit: sellAmount, affiliateBps, receiveAddress, accountNumber, slippageTolerancePercentageDecimal: _slippageTolerancePercentageDecimal, } = input;
    const { assetsById } = deps;
    const jupiterUrl = deps.config.REACT_APP_JUPITER_API_URL;
    const solAsset = assetsById[caip_1.solAssetId];
    if (!(0, helpers_1.isSupportedChainId)(sellAsset.chainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (!(0, helpers_1.isSupportedChainId)(buyAsset.chainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (buyAsset.assetId === caip_1.wrappedSolAssetId || sellAsset.assetId === caip_1.wrappedSolAssetId) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `Unsupported trade pair`,
            code: types_1.TradeQuoteError.UnsupportedTradePair,
        }));
    }
    if (!solAsset) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `solAsset should be defined`,
            code: types_1.TradeQuoteError.UnknownError,
        }));
    }
    const maybePriceResponse = await (0, helpers_1.getJupiterPrice)({
        apiUrl: jupiterUrl,
        sourceAsset: sellAsset.assetId === caip_1.solAssetId ? caip_1.wrappedSolAssetId : sellAsset.assetId,
        destinationAsset: buyAsset.assetId === caip_1.solAssetId ? caip_1.wrappedSolAssetId : buyAsset.assetId,
        commissionBps: affiliateBps,
        amount: sellAmount,
        slippageBps: _slippageTolerancePercentageDecimal
            ? (0, utils_1.convertDecimalPercentageToBasisPoints)(_slippageTolerancePercentageDecimal).toFixed()
            : undefined,
    });
    if (maybePriceResponse.isErr()) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'Quote request failed',
            code: types_1.TradeQuoteError.NoRouteFound,
        }));
    }
    const { data: priceResponse } = maybePriceResponse.unwrap();
    const getFeeData = async () => {
        const sellAdapter = deps.assertGetSolanaChainAdapter(sellAsset.chainId);
        const getFeeDataInput = {
            // used as a placeholder for the sake of loosely estimating fees
            to: constants_1.SOLANA_RANDOM_ADDRESS,
            value: sellAmount,
            chainSpecific: {
                from: constants_1.SOLANA_RANDOM_ADDRESS,
                tokenId: sellAsset.assetId === caip_1.solAssetId
                    ? undefined
                    : (0, caip_1.fromAssetId)(sellAsset.assetId).assetReference,
            },
        };
        const { fast } = await sellAdapter.getFeeData(getFeeDataInput);
        return { networkFeeCryptoBaseUnit: fast.txFee };
    };
    const adapter = deps.assertGetSolanaChainAdapter(sellAsset.chainId);
    const protocolFees = priceResponse.routePlan.reduce((acc, route) => {
        const feeAssetId = (0, caip_1.toAssetId)({
            assetReference: route.swapInfo.feeMint,
            assetNamespace: caip_1.ASSET_NAMESPACE.splToken,
            chainNamespace: caip_1.CHAIN_NAMESPACE.Solana,
            chainReference: caip_1.CHAIN_REFERENCE.SolanaMainnet,
        });
        const feeAsset = assetsById[feeAssetId];
        // If we can't find the feeAsset, we can't provide a protocol fee to display
        // But these fees exists at protocol level, it's mostly to make TS happy as we should have the market data and assets
        if (!feeAsset)
            return acc;
        acc[feeAssetId] = {
            requiresBalance: false,
            amountCryptoBaseUnit: (0, utils_1.bnOrZero)(route.swapInfo.feeAmount).toFixed(0),
            asset: feeAsset,
        };
        return acc;
    }, {});
    const rates = [];
    const feeData = await getFeeData();
    const inputOutputRate = (0, utils_2.getInputOutputRate)({
        sellAmountCryptoBaseUnit: priceResponse.inAmount,
        buyAmountCryptoBaseUnit: priceResponse.outAmount,
        sellAsset,
        buyAsset,
    });
    const slippageTolerancePercentageDecimal = 
    // Divide by 100 to get actual decimal percentage from bps
    // e.g for 0.5% bps, Jupiter represents this as 50. 50/100 = 0.5, then we div by 100 again to honour our decimal format e.g 0.5/100 = 0.005
    (0, utils_1.bn)(priceResponse.slippageBps).div(100).div(100).toString();
    const { instructions } = await (0, helpers_1.createSwapInstructions)({
        priceResponse,
        sendAddress: input.sendAddress ?? constants_1.SOLANA_RANDOM_ADDRESS,
        receiveAddress,
        affiliateBps,
        buyAsset,
        sellAsset,
        adapter,
        jupiterUrl,
    });
    const accountCreationFees = (0, helpers_1.calculateAccountCreationCosts)(instructions);
    if (accountCreationFees !== '0') {
        const solProtocolFeeAmount = (0, utils_1.bnOrZero)(protocolFees[caip_1.solAssetId]?.amountCryptoBaseUnit);
        protocolFees[caip_1.solAssetId] = {
            requiresBalance: true,
            amountCryptoBaseUnit: (0, utils_1.bnOrZero)(solProtocolFeeAmount).plus(accountCreationFees).toFixed(),
            asset: solAsset,
        };
    }
    const tradeRate = {
        id: (0, uuid_1.v4)(),
        rate: inputOutputRate,
        receiveAddress,
        potentialAffiliateBps: affiliateBps,
        affiliateBps,
        accountNumber,
        slippageTolerancePercentageDecimal,
        steps: [
            {
                buyAmountBeforeFeesCryptoBaseUnit: priceResponse.outAmount,
                buyAmountAfterFeesCryptoBaseUnit: priceResponse.outAmount,
                sellAmountIncludingProtocolFeesCryptoBaseUnit: priceResponse.inAmount,
                jupiterQuoteResponse: priceResponse,
                feeData: {
                    protocolFees,
                    ...feeData,
                },
                rate: inputOutputRate,
                source: types_1.SwapperName.Jupiter,
                buyAsset,
                sellAsset,
                accountNumber,
                allowanceContract: '0x0',
                // Swap are so fasts on solana that times are under 100ms displaying 0 or very small amount of time is not user friendly
                estimatedExecutionTimeMs: undefined,
            },
        ],
    };
    rates.push(tradeRate);
    return (0, monads_1.Ok)(rates);
};
exports.getTradeRate = getTradeRate;
