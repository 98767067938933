"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchZrxQuote = exports.fetchZrxPrice = void 0;
const contracts_1 = require("@shapeshiftoss/contracts");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const helpers_1 = require("../../utils/helpers/helpers");
const helpers_2 = require("./helpers/helpers");
const zrxService_1 = require("./zrxService");
const fetchFromZrx = async ({ quoteOrRate, buyAsset, sellAsset, sellAmountIncludingProtocolFeesCryptoBaseUnit, sellAddress, affiliateBps, slippageTolerancePercentageDecimal, zrxBaseUrl, }) => {
    const zrxService = (0, zrxService_1.zrxServiceFactory)({ baseUrl: zrxBaseUrl });
    const maybeTreasuryAddress = (() => {
        try {
            return (0, helpers_1.getTreasuryAddressFromChainId)(buyAsset.chainId);
        }
        catch (err) { }
    })();
    // Rates are gotten using ZRX /swap/permit2/price endpoint
    const endpoint = quoteOrRate === 'quote' ? 'quote' : 'price';
    // https://0x.org/docs/api#tag/Swap/operation/swap::permit2::getPrice
    // https://0x.org/docs/api#tag/Swap/operation/swap::permit2::getQuote
    const maybeZrxPriceResponse = await zrxService.get(`/swap/permit2/${endpoint}`, {
        params: {
            chainId: contracts_1.viemNetworkIdByChainId[sellAsset.chainId],
            buyToken: (0, helpers_2.assetIdToZrxToken)(buyAsset.assetId),
            sellToken: (0, helpers_2.assetIdToZrxToken)(sellAsset.assetId),
            sellAmount: sellAmountIncludingProtocolFeesCryptoBaseUnit,
            taker: sellAddress,
            swapFeeBps: parseInt(affiliateBps),
            swapFeeToken: (0, helpers_2.assetIdToZrxToken)(buyAsset.assetId),
            slippageBps: (0, utils_1.convertDecimalPercentageToBasisPoints)(slippageTolerancePercentageDecimal).toNumber(),
            swapFeeRecipient: maybeTreasuryAddress,
            feeRecipientTradeSurplus: maybeTreasuryAddress, // Where trade surplus is sent
        },
    });
    if (maybeZrxPriceResponse.isErr()) {
        return (0, monads_1.Err)(maybeZrxPriceResponse.unwrapErr());
    }
    return (0, monads_1.Ok)(maybeZrxPriceResponse.unwrap().data);
};
const fetchZrxPrice = (args) => {
    return fetchFromZrx({
        quoteOrRate: 'rate',
        ...args,
    });
};
exports.fetchZrxPrice = fetchZrxPrice;
const fetchZrxQuote = (args) => {
    return fetchFromZrx({
        quoteOrRate: 'quote',
        ...args,
    });
};
exports.fetchZrxQuote = fetchZrxQuote;
