"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProtocolFees = exports.calculateBuyAmountBeforeFeesCryptoBaseUnit = exports.calculateRate = exports.assertValidTrade = exports.isSupportedChainId = exports.zrxTokenToAssetId = exports.assetIdToZrxToken = exports.baseUrlFromChainId = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const types_1 = require("@shapeshiftoss/types");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const viem_1 = require("viem");
const types_2 = require("../../../../types");
const utils_2 = require("../../../../utils");
const types_3 = require("../../types");
const constants_1 = require("../constants");
const baseUrlFromChainId = (zrxBaseUrl, chainId) => {
    switch (chainId) {
        case types_1.KnownChainIds.EthereumMainnet:
            return `${zrxBaseUrl}ethereum/`;
        case types_1.KnownChainIds.AvalancheMainnet:
            return `${zrxBaseUrl}avalanche/`;
        case types_1.KnownChainIds.OptimismMainnet:
            return `${zrxBaseUrl}optimism/`;
        case types_1.KnownChainIds.BnbSmartChainMainnet:
            return `${zrxBaseUrl}bnbsmartchain/`;
        case types_1.KnownChainIds.PolygonMainnet:
            return `${zrxBaseUrl}polygon/`;
        case types_1.KnownChainIds.ArbitrumMainnet:
            return `${zrxBaseUrl}arbitrum/`;
        case types_1.KnownChainIds.BaseMainnet:
            return `${zrxBaseUrl}base/`;
        default:
            return (0, utils_1.assertUnreachable)(chainId);
    }
};
exports.baseUrlFromChainId = baseUrlFromChainId;
// converts an asset to zrx token (symbol or contract address)
const assetIdToZrxToken = (assetId) => {
    const { assetReference, assetNamespace } = (0, caip_1.fromAssetId)(assetId);
    return assetNamespace === 'slip44' ? constants_1.ZRX_NATIVE_ASSET_ADDRESS : assetReference;
};
exports.assetIdToZrxToken = assetIdToZrxToken;
const zrxTokenToAssetId = (token, chainId) => {
    const isDefaultAddress = (0, viem_1.getAddress)(token) === constants_1.ZRX_NATIVE_ASSET_ADDRESS;
    const { assetReference, assetNamespace } = (() => {
        if (!isDefaultAddress)
            return {
                assetReference: token,
                assetNamespace: chainId === types_1.KnownChainIds.BnbSmartChainMainnet
                    ? caip_1.ASSET_NAMESPACE.bep20
                    : caip_1.ASSET_NAMESPACE.erc20,
            };
        switch (chainId) {
            case types_1.KnownChainIds.EthereumMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.Ethereum,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case types_1.KnownChainIds.AvalancheMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.AvalancheC,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case types_1.KnownChainIds.OptimismMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.Optimism,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case types_1.KnownChainIds.BnbSmartChainMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.BnbSmartChain,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case types_1.KnownChainIds.PolygonMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.Polygon,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case types_1.KnownChainIds.ArbitrumMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.Arbitrum,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case types_1.KnownChainIds.BaseMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.Base,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            default:
                throw Error(`chainId '${chainId}' not supported`);
        }
    })();
    return (0, caip_1.toAssetId)({
        chainId,
        assetNamespace,
        assetReference,
    });
};
exports.zrxTokenToAssetId = zrxTokenToAssetId;
const isSupportedChainId = (chainId) => {
    return types_3.zrxSupportedChainIds.includes(chainId);
};
exports.isSupportedChainId = isSupportedChainId;
const assertValidTrade = ({ buyAsset, sellAsset, }) => {
    const sellAssetChainId = sellAsset.chainId;
    const buyAssetChainId = buyAsset.chainId;
    if (!(0, exports.isSupportedChainId)(sellAssetChainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_2.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (!(0, exports.isSupportedChainId)(buyAssetChainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_2.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (sellAssetChainId !== buyAssetChainId) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `cross-chain not supported - both assets must be on chainId ${sellAsset.chainId}`,
            code: types_2.TradeQuoteError.CrossChainNotSupported,
            details: { buyAsset, sellAsset },
        }));
    }
    return (0, monads_1.Ok)(true);
};
exports.assertValidTrade = assertValidTrade;
const calculateRate = ({ buyAmount, sellAmount, buyAsset, sellAsset, }) => {
    // For the rate to be valid, both amounts must be converted to the same precision
    return (0, utils_1.convertPrecision)({
        value: buyAmount,
        inputExponent: buyAsset.precision,
        outputExponent: sellAsset.precision,
    })
        .dividedBy((0, utils_1.bn)(sellAmount))
        .toFixed();
};
exports.calculateRate = calculateRate;
const calculateBuyAmountBeforeFeesCryptoBaseUnit = ({ buyAmount, fees, buyAsset, sellAsset, }) => {
    // The integrator fee is set to the buy asset, but paranoia
    if (fees.integratorFee !== null &&
        fees.integratorFee.token !== (0, exports.assetIdToZrxToken)(buyAsset.assetId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `Unhandled integrator fee asset '${fees.integratorFee.token}'`,
            code: types_2.TradeQuoteError.CrossChainNotSupported,
            details: { buyAsset, sellAsset },
        }));
    }
    // We can safely add the integrator fee now we know its the correct asset.
    const integratorFeeCryptoBaseUnit = fees.integratorFee?.amount ?? '0';
    return (0, utils_1.bn)(buyAmount).plus(integratorFeeCryptoBaseUnit).toFixed();
};
exports.calculateBuyAmountBeforeFeesCryptoBaseUnit = calculateBuyAmountBeforeFeesCryptoBaseUnit;
const getProtocolFees = ({ fees, sellAsset, assetsById, }) => {
    if (!fees.zeroExFee)
        return {};
    const assetId = (0, exports.zrxTokenToAssetId)(fees.zeroExFee.token, sellAsset.chainId);
    return {
        [assetId]: {
            requiresBalance: false,
            amountCryptoBaseUnit: fees.zeroExFee.amount,
            asset: assetsById[assetId],
        },
    };
};
exports.getProtocolFees = getProtocolFees;
