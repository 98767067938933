"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TradeExecutionEvent = exports.isSolanaFeeData = exports.TradeQuoteError = exports.SwapperName = void 0;
var SwapperName;
(function (SwapperName) {
    SwapperName["Thorchain"] = "THORChain";
    SwapperName["CowSwap"] = "CoW Swap";
    SwapperName["Zrx"] = "0x";
    SwapperName["Test"] = "Test";
    SwapperName["LIFI"] = "LI.FI";
    SwapperName["ArbitrumBridge"] = "Arbitrum Bridge";
    SwapperName["Portals"] = "Portals";
    SwapperName["Chainflip"] = "Chainflip";
    SwapperName["Jupiter"] = "Jupiter";
})(SwapperName || (exports.SwapperName = SwapperName = {}));
var TradeQuoteError;
(function (TradeQuoteError) {
    // the swapper was unable to find a quote for this pair
    TradeQuoteError["UnsupportedTradePair"] = "UnsupportedTradePair";
    // the swapper does support the pair buy couldn't find a route for the amount specified
    TradeQuoteError["NoRouteFound"] = "NoRouteFound";
    // the swapper doesn't support the chain
    TradeQuoteError["UnsupportedChain"] = "UnsupportedChain";
    // the swapper can't swap across chains
    TradeQuoteError["CrossChainNotSupported"] = "CrossChainNotSupported";
    // the swapper wasn't able to get a network fee estimate
    TradeQuoteError["NetworkFeeEstimationFailed"] = "NetworkFeeEstimationFailed";
    // trading has been halted upstream
    TradeQuoteError["TradingHalted"] = "TradingHalted";
    // the sell amount was lower than the minimum defined upstream
    TradeQuoteError["SellAmountBelowMinimum"] = "SellAmountBelowMinimum";
    // the fees exceed the sell amount
    TradeQuoteError["SellAmountBelowTradeFee"] = "SellAmountBelowTradeFee";
    // the swapper has exceeded its rate limit
    TradeQuoteError["RateLimitExceeded"] = "RateLimitExceeded";
    // catch-all for XHRs that can fail
    TradeQuoteError["QueryFailed"] = "QueryFailed";
    // the response from the API was invalid or unexpected
    TradeQuoteError["InvalidResponse"] = "InvalidResponse";
    // an assertion triggered, indicating a bug
    TradeQuoteError["InternalError"] = "InternalError";
    // The max. slippage allowed for this trade has been exceeded at final quote time, as returned by the active quote swapper's API upstream
    TradeQuoteError["FinalQuoteMaxSlippageExceeded"] = "FinalQuoteMaxSlippageExceeded";
    // Execution reverted at final quote time, as returned by the active quote swapper's API upstream
    TradeQuoteError["FinalQuoteExecutionReverted"] = "FinalQuoteExecutionReverted";
    // catch-all for unknown issues
    TradeQuoteError["UnknownError"] = "UnknownError";
})(TradeQuoteError || (exports.TradeQuoteError = TradeQuoteError = {}));
const isSolanaFeeData = (chainSpecific) => {
    return Boolean(chainSpecific && 'priorityFee' in chainSpecific);
};
exports.isSolanaFeeData = isSolanaFeeData;
var TradeExecutionEvent;
(function (TradeExecutionEvent) {
    TradeExecutionEvent["SellTxHash"] = "sellTxHash";
    TradeExecutionEvent["Status"] = "status";
    TradeExecutionEvent["Success"] = "success";
    TradeExecutionEvent["Fail"] = "fail";
    TradeExecutionEvent["Error"] = "error";
})(TradeExecutionEvent || (exports.TradeExecutionEvent = TradeExecutionEvent = {}));
