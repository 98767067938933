"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getZrxTradeQuote = void 0;
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const contracts_1 = require("@shapeshiftoss/contracts");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const uuid_1 = require("uuid");
const constants_1 = require("../../../constants");
const types_1 = require("../../../types");
const utils_2 = require("../../../utils");
const helpers_1 = require("../../utils/helpers/helpers");
const fetchFromZrx_1 = require("../utils/fetchFromZrx");
const helpers_2 = require("../utils/helpers/helpers");
async function getZrxTradeQuote(input, assertGetEvmChainAdapter, assetsById, zrxBaseUrl) {
    const { sellAsset, buyAsset, accountNumber, receiveAddress, affiliateBps, potentialAffiliateBps, chainId, supportsEIP1559, sellAmountIncludingProtocolFeesCryptoBaseUnit, } = input;
    const assertion = (0, helpers_2.assertValidTrade)({ buyAsset, sellAsset });
    if (assertion.isErr())
        return (0, monads_1.Err)(assertion.unwrapErr());
    const slippageTolerancePercentageDecimal = input.slippageTolerancePercentageDecimal ??
        (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.Zrx);
    const maybeZrxQuoteResponse = await (0, fetchFromZrx_1.fetchZrxQuote)({
        buyAsset,
        sellAsset,
        sellAmountIncludingProtocolFeesCryptoBaseUnit,
        // Cross-account not supported for ZRX
        sellAddress: receiveAddress,
        affiliateBps,
        slippageTolerancePercentageDecimal,
        zrxBaseUrl,
    });
    if (maybeZrxQuoteResponse.isErr())
        return (0, monads_1.Err)(maybeZrxQuoteResponse.unwrapErr());
    const zrxQuoteResponse = maybeZrxQuoteResponse.unwrap();
    const { sellAmount, buyAmount, fees, permit2, transaction } = zrxQuoteResponse;
    const permit2Eip712 = permit2?.eip712;
    if (!(0, helpers_1.isNativeEvmAsset)(sellAsset.assetId) && !permit2Eip712) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'Missing required Permit2 metadata from 0x response',
            code: types_1.TradeQuoteError.InvalidResponse,
            details: { transaction, permit2Eip712 },
        }));
    }
    if (!transaction) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'Missing required transaction metadata from 0x response',
            code: types_1.TradeQuoteError.InvalidResponse,
            details: { transaction, permit2Eip712 },
        }));
    }
    const transactionMetadata = {
        to: transaction.to,
        data: transaction.data,
        gasPrice: transaction.gasPrice || undefined,
        gas: transaction.gas || undefined,
        value: transaction.value,
    };
    const rate = (0, helpers_2.calculateRate)({ buyAmount, sellAmount, buyAsset, sellAsset });
    const buyAmountBeforeFeesCryptoBaseUnit = (0, helpers_2.calculateBuyAmountBeforeFeesCryptoBaseUnit)({
        buyAmount,
        fees,
        buyAsset,
        sellAsset,
    });
    try {
        const adapter = assertGetEvmChainAdapter(chainId);
        const { average } = await adapter.getGasFeeData();
        const networkFeeCryptoBaseUnit = chain_adapters_1.evm.calcNetworkFeeCryptoBaseUnit({
            ...average,
            supportsEIP1559: Boolean(supportsEIP1559),
            // add gas limit buffer to account for the fact we perform all of our validation on the trade quote estimations
            // which are inaccurate and not what we use for the tx to broadcast
            gasLimit: (0, utils_1.bnOrZero)(transaction.gas).times(1.2).toFixed(),
        });
        return (0, monads_1.Ok)({
            id: (0, uuid_1.v4)(),
            receiveAddress,
            potentialAffiliateBps,
            affiliateBps,
            // Slippage protection is always enabled for 0x api v2 unlike api v1 which was only supported on specific pairs.
            slippageTolerancePercentageDecimal,
            rate,
            steps: [
                {
                    estimatedExecutionTimeMs: undefined,
                    allowanceContract: (0, helpers_1.isNativeEvmAsset)(sellAsset.assetId) ? undefined : contracts_1.PERMIT2_CONTRACT,
                    buyAsset,
                    sellAsset,
                    accountNumber,
                    rate,
                    feeData: {
                        protocolFees: (0, helpers_2.getProtocolFees)({ fees, sellAsset, assetsById }),
                        networkFeeCryptoBaseUnit,
                    },
                    buyAmountBeforeFeesCryptoBaseUnit,
                    buyAmountAfterFeesCryptoBaseUnit: buyAmount,
                    sellAmountIncludingProtocolFeesCryptoBaseUnit,
                    source: types_1.SwapperName.Zrx,
                    permit2Eip712: permit2Eip712,
                    zrxTransactionMetadata: transactionMetadata,
                },
            ],
        });
    }
    catch (err) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'failed to get fee data',
            cause: err,
            code: types_1.TradeQuoteError.NetworkFeeEstimationFailed,
        }));
    }
}
exports.getZrxTradeQuote = getZrxTradeQuote;
