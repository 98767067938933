"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTradeQuote = exports._getTradeQuote = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const monads_1 = require("@sniptt/monads");
const uuid_1 = require("uuid");
const constants_1 = require("../../../constants");
const types_1 = require("../../../types");
const utils_1 = require("../../../utils");
const constants_2 = require("../constants");
const chainflipService_1 = require("../utils/chainflipService");
const getEvmTxFees_1 = require("../utils/getEvmTxFees");
const getUtxoTxFees_1 = require("../utils/getUtxoTxFees");
const helpers_1 = require("../utils/helpers");
const _getTradeQuote = async (input, deps) => {
    const { sellAsset, buyAsset, accountNumber, receiveAddress, sellAmountIncludingProtocolFeesCryptoBaseUnit: sellAmount, affiliateBps: commissionBps, } = input;
    if (!(0, helpers_1.isSupportedChainId)(sellAsset.chainId)) {
        return (0, monads_1.Err)((0, utils_1.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (!(0, helpers_1.isSupportedChainId)(buyAsset.chainId)) {
        return (0, monads_1.Err)((0, utils_1.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (!(0, helpers_1.isSupportedAssetId)(sellAsset.chainId, sellAsset.assetId)) {
        return (0, monads_1.Err)((0, utils_1.makeSwapErrorRight)({
            message: `asset '${sellAsset.name}' on chainId '${sellAsset.chainId}' not supported`,
            code: types_1.TradeQuoteError.UnsupportedTradePair,
            details: { chainId: sellAsset.chainId, assetId: sellAsset.assetId },
        }));
    }
    if (!(0, helpers_1.isSupportedAssetId)(buyAsset.chainId, buyAsset.assetId)) {
        return (0, monads_1.Err)((0, utils_1.makeSwapErrorRight)({
            message: `asset '${buyAsset.name}' on chainId '${buyAsset.chainId}' not supported`,
            code: types_1.TradeQuoteError.UnsupportedTradePair,
            details: { chainId: buyAsset.chainId, assetId: buyAsset.assetId },
        }));
    }
    const brokerUrl = deps.config.REACT_APP_CHAINFLIP_API_URL;
    const apiKey = deps.config.REACT_APP_CHAINFLIP_API_KEY;
    const sourceAsset = await (0, helpers_1.getChainFlipIdFromAssetId)({
        assetId: sellAsset.assetId,
        brokerUrl,
    });
    const destinationAsset = await (0, helpers_1.getChainFlipIdFromAssetId)({
        assetId: buyAsset.assetId,
        brokerUrl,
    });
    // Subtract the BaaS fee to end up at the final displayed commissionBps
    let serviceCommission = parseInt(commissionBps) - constants_2.CHAINFLIP_BAAS_COMMISSION;
    if (serviceCommission < 0)
        serviceCommission = 0;
    const maybeQuoteResponse = await chainflipService_1.chainflipService.get(`${brokerUrl}/quotes-native` +
        `?apiKey=${apiKey}` +
        `&sourceAsset=${sourceAsset}` +
        `&destinationAsset=${destinationAsset}` +
        `&amount=${sellAmount}` +
        `&commissionBps=${serviceCommission}`);
    if (maybeQuoteResponse.isErr()) {
        const error = maybeQuoteResponse.unwrapErr();
        const cause = error.cause;
        if (cause.message.includes('code 400') &&
            cause.response.data.detail.includes('Amount outside asset bounds')) {
            return (0, monads_1.Err)((0, utils_1.createTradeAmountTooSmallErr)({
                assetId: sellAsset.assetId,
                minAmountCryptoBaseUnit: cause.response.data.errors.minimalAmountNative[0],
            }));
        }
        return (0, monads_1.Err)((0, utils_1.makeSwapErrorRight)({
            message: 'Quote request failed',
            code: types_1.TradeQuoteError.NoRouteFound,
        }));
    }
    const { data: quoteResponse } = maybeQuoteResponse.unwrap();
    const getFeeData = async () => {
        const { chainNamespace } = (0, caip_1.fromAssetId)(sellAsset.assetId);
        switch (chainNamespace) {
            case caip_1.CHAIN_NAMESPACE.Evm: {
                const sellAdapter = deps.assertGetEvmChainAdapter(sellAsset.chainId);
                const networkFeeCryptoBaseUnit = await (0, getEvmTxFees_1.getEvmTxFees)({
                    adapter: sellAdapter,
                    supportsEIP1559: input.supportsEIP1559,
                    sendAsset: sourceAsset,
                });
                return { networkFeeCryptoBaseUnit };
            }
            case caip_1.CHAIN_NAMESPACE.Utxo: {
                const sellAdapter = deps.assertGetUtxoChainAdapter(sellAsset.chainId);
                const publicKey = input.xpub;
                const feeData = await (0, getUtxoTxFees_1.getUtxoTxFees)({
                    sellAmountCryptoBaseUnit: sellAmount,
                    sellAdapter,
                    publicKey,
                });
                return feeData;
            }
            case caip_1.CHAIN_NAMESPACE.Solana: {
                const sellAdapter = deps.assertGetSolanaChainAdapter(sellAsset.chainId);
                const getFeeDataInput = {
                    // Simulates a self-send, since we don't know the to just yet at this stage
                    to: input.sendAddress,
                    value: sellAmount,
                    chainSpecific: {
                        from: input.sendAddress,
                        tokenId: sellAsset.assetId === caip_1.solAssetId
                            ? undefined
                            : (0, caip_1.fromAssetId)(sellAsset.assetId).assetReference,
                    },
                };
                const { fast } = await sellAdapter.getFeeData(getFeeDataInput);
                return { networkFeeCryptoBaseUnit: fast.txFee };
            }
            default:
                throw new Error('Unsupported chainNamespace');
        }
    };
    const getFeeAsset = (fee) => {
        if (fee.type === 'ingress' || fee.type === 'boost')
            return sellAsset;
        if (fee.type === 'egress')
            return buyAsset;
        if (fee.type === 'liquidity' && fee.asset === sourceAsset)
            return sellAsset;
        if (fee.type === 'liquidity' && fee.asset === destinationAsset)
            return buyAsset;
        if (fee.type === 'liquidity' && fee.asset === 'usdc.eth')
            return constants_2.usdcAsset;
        if (fee.type === 'network')
            return constants_2.usdcAsset;
    };
    const getProtocolFees = (singleQuoteResponse) => {
        const protocolFees = {};
        for (const fee of singleQuoteResponse.includedFees) {
            if (fee.type === 'broker')
                continue;
            const asset = getFeeAsset(fee);
            if (!(asset.assetId in protocolFees)) {
                protocolFees[asset.assetId] = {
                    amountCryptoBaseUnit: '0',
                    requiresBalance: false,
                    asset,
                };
            }
            protocolFees[asset.assetId].amountCryptoBaseUnit = (BigInt(protocolFees[asset.assetId].amountCryptoBaseUnit) + BigInt(fee.amountNative)).toString();
        }
        return protocolFees;
    };
    const getQuoteRate = (sellAmountCryptoBaseUnit, buyAmountCryptoBaseUnit) => {
        return (0, utils_1.getInputOutputRate)({
            sellAmountCryptoBaseUnit,
            buyAmountCryptoBaseUnit,
            sellAsset,
            buyAsset,
        });
    };
    const getSwapSource = (swapType, isBoosted) => {
        return swapType === constants_2.CHAINFLIP_REGULAR_QUOTE
            ? isBoosted
                ? constants_2.CHAINFLIP_BOOST_SWAP_SOURCE
                : constants_2.CHAINFLIP_SWAP_SOURCE
            : isBoosted
                ? constants_2.CHAINFLIP_DCA_BOOST_SWAP_SOURCE
                : constants_2.CHAINFLIP_DCA_SWAP_SOURCE;
    };
    const quotes = [];
    for (const singleQuoteResponse of quoteResponse) {
        const isStreaming = singleQuoteResponse.type === constants_2.CHAINFLIP_DCA_QUOTE;
        const feeData = await getFeeData();
        if (isStreaming && !deps.config.REACT_APP_FEATURE_CHAINFLIP_SWAP_DCA) {
            // DCA currently disabled - Streaming swap logic is very much tied to THOR currently and will deserve its own PR to generalize
            // Even if we manage to get DCA swaps to execute, we wouldn't manage to properly poll with current web THOR-centric arch
            continue;
        }
        if (singleQuoteResponse.boostQuote) {
            const boostRate = getQuoteRate(singleQuoteResponse.boostQuote.ingressAmountNative, singleQuoteResponse.boostQuote.egressAmountNative);
            const boostTradeQuote = {
                id: (0, uuid_1.v4)(),
                rate: boostRate,
                receiveAddress,
                potentialAffiliateBps: commissionBps,
                affiliateBps: commissionBps,
                isStreaming,
                slippageTolerancePercentageDecimal: input.slippageTolerancePercentageDecimal ??
                    (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.Chainflip),
                steps: [
                    {
                        buyAmountBeforeFeesCryptoBaseUnit: singleQuoteResponse.boostQuote.egressAmountNative,
                        buyAmountAfterFeesCryptoBaseUnit: singleQuoteResponse.boostQuote.egressAmountNative,
                        sellAmountIncludingProtocolFeesCryptoBaseUnit: singleQuoteResponse.boostQuote.ingressAmountNative,
                        feeData: {
                            protocolFees: getProtocolFees(singleQuoteResponse.boostQuote),
                            ...feeData,
                        },
                        rate: boostRate,
                        source: getSwapSource(singleQuoteResponse.type, true),
                        buyAsset,
                        sellAsset,
                        accountNumber,
                        allowanceContract: '0x0',
                        estimatedExecutionTimeMs: (singleQuoteResponse.boostQuote.estimatedDurationsSeconds.deposit +
                            singleQuoteResponse.boostQuote.estimatedDurationsSeconds.swap) *
                            1000,
                    },
                ],
            };
            quotes.push(boostTradeQuote);
        }
        const rate = getQuoteRate(singleQuoteResponse.ingressAmountNative, singleQuoteResponse.egressAmountNative);
        const tradeQuote = {
            id: (0, uuid_1.v4)(),
            rate,
            receiveAddress,
            potentialAffiliateBps: commissionBps,
            affiliateBps: commissionBps,
            isStreaming,
            slippageTolerancePercentageDecimal: input.slippageTolerancePercentageDecimal ??
                (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.Chainflip),
            steps: [
                {
                    buyAmountBeforeFeesCryptoBaseUnit: singleQuoteResponse.egressAmountNative,
                    buyAmountAfterFeesCryptoBaseUnit: singleQuoteResponse.egressAmountNative,
                    sellAmountIncludingProtocolFeesCryptoBaseUnit: singleQuoteResponse.ingressAmountNative,
                    feeData: {
                        protocolFees: getProtocolFees(singleQuoteResponse),
                        ...feeData,
                    },
                    rate,
                    source: getSwapSource(singleQuoteResponse.type, false),
                    buyAsset,
                    sellAsset,
                    accountNumber,
                    allowanceContract: '0x0',
                    estimatedExecutionTimeMs: (singleQuoteResponse.estimatedDurationsSeconds.deposit +
                        singleQuoteResponse.estimatedDurationsSeconds.swap) *
                        1000,
                },
            ],
        };
        quotes.push(tradeQuote);
    }
    return (0, monads_1.Ok)(quotes);
};
exports._getTradeQuote = _getTradeQuote;
const getTradeQuote = async (input, deps) => {
    const { accountNumber } = input;
    if (accountNumber === undefined) {
        return (0, monads_1.Err)((0, utils_1.makeSwapErrorRight)({
            message: `accountNumber is required`,
            code: types_1.TradeQuoteError.UnknownError,
        }));
    }
    const quotes = await (0, exports._getTradeQuote)(input, deps);
    return quotes;
};
exports.getTradeQuote = getTradeQuote;
