"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jupiterService = void 0;
const types_1 = require("../../../types");
const utils_1 = require("../../../utils");
const maxAgeMillis = 15 * 1000;
const cachedUrls = [];
const axiosConfig = {
    timeout: 10000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
};
const jupiterServiceBase = (0, utils_1.createCache)(maxAgeMillis, cachedUrls, axiosConfig);
exports.jupiterService = (0, utils_1.makeSwapperAxiosServiceMonadic)(jupiterServiceBase, types_1.SwapperName.Jupiter);
