"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAutoSlippageSupportedBySwapper = exports.getDefaultSlippageDecimalPercentageForSwapper = exports.swappers = exports.QUOTE_TIMEOUT_ERROR = exports.TRADE_POLL_INTERVAL_MILLISECONDS = exports.QUOTE_TIMEOUT_MS = exports.DEFAULT_GET_TRADE_QUOTE_POLLING_INTERVAL = void 0;
const utils_1 = require("@shapeshiftoss/utils");
const constants_1 = require("./cowswap-utils/constants");
const ArbitrumBridgeSwapper_1 = require("./swappers/ArbitrumBridgeSwapper/ArbitrumBridgeSwapper");
const endpoints_1 = require("./swappers/ArbitrumBridgeSwapper/endpoints");
const constants_2 = require("./swappers/ArbitrumBridgeSwapper/utils/constants");
const ChainflipSwapper_1 = require("./swappers/ChainflipSwapper/ChainflipSwapper");
const constants_3 = require("./swappers/ChainflipSwapper/constants");
const endpoints_2 = require("./swappers/ChainflipSwapper/endpoints");
const CowSwapper_1 = require("./swappers/CowSwapper/CowSwapper");
const endpoints_3 = require("./swappers/CowSwapper/endpoints");
const endpoints_4 = require("./swappers/JupiterSwapper/endpoints");
const JupiterSwapper_1 = require("./swappers/JupiterSwapper/JupiterSwapper");
const constants_4 = require("./swappers/JupiterSwapper/utils/constants");
const endpoints_5 = require("./swappers/LifiSwapper/endpoints");
const LifiSwapper_1 = require("./swappers/LifiSwapper/LifiSwapper");
const constants_5 = require("./swappers/LifiSwapper/utils/constants");
const constants_6 = require("./swappers/PortalsSwapper/constants");
const endpoints_6 = require("./swappers/PortalsSwapper/endpoints");
const PortalsSwapper_1 = require("./swappers/PortalsSwapper/PortalsSwapper");
const constants_7 = require("./swappers/ThorchainSwapper/constants");
const endpoints_7 = require("./swappers/ThorchainSwapper/endpoints");
const ThorchainSwapper_1 = require("./swappers/ThorchainSwapper/ThorchainSwapper");
const endpoints_8 = require("./swappers/ZrxSwapper/endpoints");
const constants_8 = require("./swappers/ZrxSwapper/utils/constants");
const ZrxSwapper_1 = require("./swappers/ZrxSwapper/ZrxSwapper");
const types_1 = require("./types");
const utils_2 = require("./utils");
exports.DEFAULT_GET_TRADE_QUOTE_POLLING_INTERVAL = 20000;
exports.QUOTE_TIMEOUT_MS = 60000;
exports.TRADE_POLL_INTERVAL_MILLISECONDS = 10000;
exports.QUOTE_TIMEOUT_ERROR = (0, utils_2.makeSwapErrorRight)({
    message: `quote timed out after ${exports.QUOTE_TIMEOUT_MS / 1000}s`,
});
// PartialRecord not used to ensure exhaustiveness
exports.swappers = {
    [types_1.SwapperName.LIFI]: {
        ...LifiSwapper_1.lifiSwapper,
        ...endpoints_5.lifiApi,
        supportedChainIds: constants_5.LIFI_SUPPORTED_CHAIN_IDS,
        pollingInterval: LifiSwapper_1.LIFI_GET_TRADE_QUOTE_POLLING_INTERVAL,
    },
    [types_1.SwapperName.Thorchain]: {
        ...ThorchainSwapper_1.thorchainSwapper,
        ...endpoints_7.thorchainApi,
        supportedChainIds: constants_7.THORCHAIN_SUPPORTED_CHAIN_IDS,
        pollingInterval: exports.DEFAULT_GET_TRADE_QUOTE_POLLING_INTERVAL,
    },
    [types_1.SwapperName.Zrx]: {
        ...ZrxSwapper_1.zrxSwapper,
        ...endpoints_8.zrxApi,
        supportedChainIds: constants_8.ZRX_SUPPORTED_CHAIN_IDS,
        pollingInterval: exports.DEFAULT_GET_TRADE_QUOTE_POLLING_INTERVAL,
    },
    [types_1.SwapperName.CowSwap]: {
        ...CowSwapper_1.cowSwapper,
        ...endpoints_3.cowApi,
        supportedChainIds: constants_1.COW_SWAP_SUPPORTED_CHAIN_IDS,
        pollingInterval: exports.DEFAULT_GET_TRADE_QUOTE_POLLING_INTERVAL,
    },
    [types_1.SwapperName.ArbitrumBridge]: {
        ...ArbitrumBridgeSwapper_1.arbitrumBridgeSwapper,
        ...endpoints_1.arbitrumBridgeApi,
        supportedChainIds: constants_2.ARBITRUM_BRIDGE_SUPPORTED_CHAIN_IDS,
        pollingInterval: exports.DEFAULT_GET_TRADE_QUOTE_POLLING_INTERVAL,
    },
    [types_1.SwapperName.Portals]: {
        ...PortalsSwapper_1.portalsSwapper,
        ...endpoints_6.portalsApi,
        supportedChainIds: constants_6.PORTALS_SUPPORTED_CHAIN_IDS,
        pollingInterval: exports.DEFAULT_GET_TRADE_QUOTE_POLLING_INTERVAL,
    },
    [types_1.SwapperName.Chainflip]: {
        ...ChainflipSwapper_1.chainflipSwapper,
        ...endpoints_2.chainflipApi,
        supportedChainIds: constants_3.CHAINFLIP_SUPPORTED_CHAIN_IDS,
        pollingInterval: exports.DEFAULT_GET_TRADE_QUOTE_POLLING_INTERVAL,
    },
    [types_1.SwapperName.Jupiter]: {
        ...JupiterSwapper_1.jupiterSwapper,
        ...endpoints_4.jupiterApi,
        supportedChainIds: constants_4.JUPITER_SUPPORTED_CHAIN_IDS,
        pollingInterval: exports.DEFAULT_GET_TRADE_QUOTE_POLLING_INTERVAL,
    },
    [types_1.SwapperName.Test]: undefined,
};
// Slippage defaults. Don't export these to ensure the getDefaultSlippageDecimalPercentageForSwapper helper function is used.
const DEFAULT_SLIPPAGE_DECIMAL_PERCENTAGE = '0.002'; // .2%
const DEFAULT_COWSWAP_SLIPPAGE_DECIMAL_PERCENTAGE = '0.005'; // .5%
const DEFAULT_PORTALS_SLIPPAGE_DECIMAL_PERCENTAGE = '0.025'; // 2.5%
const DEFAULT_LIFI_SLIPPAGE_DECIMAL_PERCENTAGE = '0.005'; // .5%
const DEFAULT_THOR_SLIPPAGE_DECIMAL_PERCENTAGE = '0.01'; // 1%
const DEFAULT_ARBITRUM_BRIDGE_SLIPPAGE_DECIMAL_PERCENTAGE = '0'; // no slippage for Arbitrum Bridge, so no slippage tolerance
const DEFAULT_CHAINFLIP_SLIPPAGE_DECIMAL_PERCENTAGE = '0.02'; // 2%
const getDefaultSlippageDecimalPercentageForSwapper = (swapperName) => {
    if (swapperName === undefined)
        return DEFAULT_SLIPPAGE_DECIMAL_PERCENTAGE;
    switch (swapperName) {
        case types_1.SwapperName.Zrx:
        case types_1.SwapperName.Test:
            return DEFAULT_SLIPPAGE_DECIMAL_PERCENTAGE;
        case types_1.SwapperName.LIFI:
            return DEFAULT_LIFI_SLIPPAGE_DECIMAL_PERCENTAGE;
        case types_1.SwapperName.CowSwap:
            return DEFAULT_COWSWAP_SLIPPAGE_DECIMAL_PERCENTAGE;
        case types_1.SwapperName.Portals:
            return DEFAULT_PORTALS_SLIPPAGE_DECIMAL_PERCENTAGE;
        case types_1.SwapperName.Thorchain:
            return DEFAULT_THOR_SLIPPAGE_DECIMAL_PERCENTAGE;
        case types_1.SwapperName.ArbitrumBridge:
            return DEFAULT_ARBITRUM_BRIDGE_SLIPPAGE_DECIMAL_PERCENTAGE;
        case types_1.SwapperName.Chainflip:
            return DEFAULT_CHAINFLIP_SLIPPAGE_DECIMAL_PERCENTAGE;
        case types_1.SwapperName.Jupiter:
            throw new Error('Default slippage not supported by Jupiter');
        default:
            return (0, utils_1.assertUnreachable)(swapperName);
    }
};
exports.getDefaultSlippageDecimalPercentageForSwapper = getDefaultSlippageDecimalPercentageForSwapper;
const isAutoSlippageSupportedBySwapper = (swapperName) => {
    switch (swapperName) {
        case types_1.SwapperName.Jupiter:
            return true;
        default:
            return false;
    }
};
exports.isAutoSlippageSupportedBySwapper = isAutoSlippageSupportedBySwapper;
