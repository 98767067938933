"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getZrxTradeRate = void 0;
const monads_1 = require("@sniptt/monads");
const uuid_1 = require("uuid");
const constants_1 = require("../../../constants");
const types_1 = require("../../../types");
const fetchFromZrx_1 = require("../utils/fetchFromZrx");
const helpers_1 = require("../utils/helpers/helpers");
async function getZrxTradeRate(input, assetsById, zrxBaseUrl) {
    const { sellAsset, buyAsset, accountNumber, receiveAddress, affiliateBps, potentialAffiliateBps, sellAmountIncludingProtocolFeesCryptoBaseUnit, } = input;
    const assertion = (0, helpers_1.assertValidTrade)({ buyAsset, sellAsset });
    if (assertion.isErr())
        return (0, monads_1.Err)(assertion.unwrapErr());
    const slippageTolerancePercentageDecimal = input.slippageTolerancePercentageDecimal ??
        (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.Zrx);
    const maybeZrxPriceResponse = await (0, fetchFromZrx_1.fetchZrxPrice)({
        buyAsset,
        sellAsset,
        sellAmountIncludingProtocolFeesCryptoBaseUnit,
        // Cross-account not supported for ZRX
        sellAddress: receiveAddress,
        affiliateBps,
        slippageTolerancePercentageDecimal,
        zrxBaseUrl,
    });
    if (maybeZrxPriceResponse.isErr())
        return (0, monads_1.Err)(maybeZrxPriceResponse.unwrapErr());
    const zrxPriceResponse = maybeZrxPriceResponse.unwrap();
    const { buyAmount, sellAmount, fees, totalNetworkFee } = zrxPriceResponse;
    const rate = (0, helpers_1.calculateRate)({ buyAmount, sellAmount, buyAsset, sellAsset });
    const buyAmountBeforeFeesCryptoBaseUnit = (0, helpers_1.calculateBuyAmountBeforeFeesCryptoBaseUnit)({
        buyAmount,
        fees,
        buyAsset,
        sellAsset,
    });
    return (0, monads_1.Ok)({
        id: (0, uuid_1.v4)(),
        accountNumber: undefined,
        receiveAddress: undefined,
        potentialAffiliateBps,
        affiliateBps,
        // Slippage protection is always enabled for 0x api v2 unlike api v1 which was only supported on specific pairs.
        slippageTolerancePercentageDecimal,
        rate,
        steps: [
            {
                estimatedExecutionTimeMs: undefined,
                buyAsset,
                sellAsset,
                accountNumber,
                rate,
                feeData: {
                    protocolFees: (0, helpers_1.getProtocolFees)({ fees, sellAsset, assetsById }),
                    networkFeeCryptoBaseUnit: totalNetworkFee,
                },
                buyAmountBeforeFeesCryptoBaseUnit,
                buyAmountAfterFeesCryptoBaseUnit: buyAmount,
                sellAmountIncludingProtocolFeesCryptoBaseUnit,
                source: types_1.SwapperName.Zrx,
            },
        ],
    });
}
exports.getZrxTradeRate = getZrxTradeRate;
