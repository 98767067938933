"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jupiterSwapper = void 0;
const __1 = require("../..");
const constants_1 = require("./utils/constants");
exports.jupiterSwapper = {
    executeSolanaTransaction: __1.executeSolanaTransaction,
    filterAssetIdsBySellable: (assets) => {
        return Promise.resolve(assets
            .filter(asset => {
            const { chainId } = asset;
            return constants_1.jupiterSupportedChainIds.includes(chainId);
        })
            .map(asset => asset.assetId));
    },
    filterBuyAssetsBySellAssetId: (input) => {
        return Promise.resolve(input.assets
            .filter(asset => {
            const { chainId } = asset;
            return constants_1.jupiterSupportedChainIds.includes(chainId);
        })
            .map(asset => asset.assetId));
    },
};
