"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chainflipApi = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const unchained_client_1 = require("@shapeshiftoss/unchained-client");
const utils_1 = require("../../utils");
const constants_1 = require("./constants");
const getTradeQuote_1 = require("./swapperApi/getTradeQuote");
const getTradeRate_1 = require("./swapperApi/getTradeRate");
const chainflipService_1 = require("./utils/chainflipService");
const getLatestChainflipStatusMessage_1 = require("./utils/getLatestChainflipStatusMessage");
const helpers_1 = require("./utils/helpers");
// Persists the ID so we can look it up later when checking the status
const tradeQuoteMetadata = new Map();
exports.chainflipApi = {
    getTradeQuote: getTradeQuote_1.getTradeQuote,
    getTradeRate: getTradeRate_1.getTradeRate,
    getUnsignedEvmTransaction: async ({ chainId, from, tradeQuote, assertGetEvmChainAdapter, config, supportsEIP1559, }) => {
        if (!(0, utils_1.isExecutableTradeQuote)(tradeQuote))
            throw Error('Unable to execute trade');
        const brokerUrl = config.REACT_APP_CHAINFLIP_API_URL;
        const apiKey = config.REACT_APP_CHAINFLIP_API_KEY;
        const step = tradeQuote.steps[0];
        const isTokenSend = (0, utils_1.isToken)(step.sellAsset.assetId);
        const sourceAsset = await (0, helpers_1.getChainFlipIdFromAssetId)({
            assetId: step.sellAsset.assetId,
            brokerUrl,
        });
        const destinationAsset = await (0, helpers_1.getChainFlipIdFromAssetId)({
            assetId: step.buyAsset.assetId,
            brokerUrl,
        });
        const minimumPrice = (0, helpers_1.calculateChainflipMinPrice)({
            slippageTolerancePercentageDecimal: tradeQuote.slippageTolerancePercentageDecimal,
            sellAsset: step.sellAsset,
            buyAsset: step.buyAsset,
            buyAmountAfterFeesCryptoBaseUnit: step.buyAmountAfterFeesCryptoBaseUnit,
            sellAmountIncludingProtocolFeesCryptoBaseUnit: step.sellAmountIncludingProtocolFeesCryptoBaseUnit,
        });
        // Subtract the BaaS fee to end up at the final displayed commissionBps
        let serviceCommission = parseInt(tradeQuote.affiliateBps) - constants_1.CHAINFLIP_BAAS_COMMISSION;
        if (serviceCommission < 0)
            serviceCommission = 0;
        const maybeSwapResponse = await (0, helpers_1.getChainFlipSwap)({
            brokerUrl,
            apiKey,
            sourceAsset,
            destinationAsset,
            destinationAddress: tradeQuote.receiveAddress,
            minimumPrice,
            refundAddress: from,
            commissionBps: serviceCommission,
            boostFee: 0,
        });
        if (maybeSwapResponse.isErr()) {
            const error = maybeSwapResponse.unwrapErr();
            const cause = error.cause;
            throw Error(cause.response.data.detail);
        }
        const { data: swapResponse } = maybeSwapResponse.unwrap();
        if (!swapResponse.id)
            throw Error('missing swap ID');
        tradeQuoteMetadata.set(tradeQuote.id, swapResponse);
        const depositAddress = swapResponse.address;
        const { assetReference } = (0, caip_1.fromAssetId)(step.sellAsset.assetId);
        const adapter = assertGetEvmChainAdapter(step.sellAsset.chainId);
        const getFeeDataInput = {
            to: depositAddress,
            value: step.sellAmountIncludingProtocolFeesCryptoBaseUnit,
            chainSpecific: {
                from,
                contractAddress: isTokenSend ? assetReference : undefined,
                data: undefined,
            },
            sendMax: false,
        };
        const feeData = await adapter.getFeeData(getFeeDataInput);
        const fees = feeData[chain_adapters_1.FeeDataKey.Average];
        if (!(0, utils_1.isExecutableTradeStep)(step))
            throw Error('Unable to execute trade step');
        const unsignedTxInput = await adapter.buildSendApiTransaction({
            to: depositAddress,
            from,
            value: step.sellAmountIncludingProtocolFeesCryptoBaseUnit,
            accountNumber: step.accountNumber,
            chainSpecific: {
                gasLimit: fees.chainSpecific.gasLimit,
                contractAddress: isTokenSend ? assetReference : undefined,
                ...(supportsEIP1559
                    ? {
                        maxFeePerGas: fees.chainSpecific.maxFeePerGas,
                        maxPriorityFeePerGas: fees.chainSpecific.maxPriorityFeePerGas,
                    }
                    : {
                        gasPrice: fees.chainSpecific.gasPrice,
                    }),
            },
        });
        return {
            chainId: Number((0, caip_1.fromChainId)(chainId).chainReference),
            data: unsignedTxInput.data,
            to: unsignedTxInput.to,
            from,
            value: unsignedTxInput.value,
            gasLimit: unsignedTxInput.gasLimit,
            maxFeePerGas: unsignedTxInput.maxFeePerGas,
            maxPriorityFeePerGas: unsignedTxInput.maxPriorityFeePerGas,
            gasPrice: unsignedTxInput.gasPrice,
        };
    },
    getUnsignedUtxoTransaction: async ({ tradeQuote, senderAddress, xpub, accountType, assertGetUtxoChainAdapter, config, }) => {
        if (!(0, utils_1.isExecutableTradeQuote)(tradeQuote))
            throw Error('Unable to execute trade');
        const brokerUrl = config.REACT_APP_CHAINFLIP_API_URL;
        const apiKey = config.REACT_APP_CHAINFLIP_API_KEY;
        const step = tradeQuote.steps[0];
        if (!(0, utils_1.isExecutableTradeStep)(step))
            throw Error('Unable to execute step');
        const sourceAsset = await (0, helpers_1.getChainFlipIdFromAssetId)({
            assetId: step.sellAsset.assetId,
            brokerUrl,
        });
        const destinationAsset = await (0, helpers_1.getChainFlipIdFromAssetId)({
            assetId: step.buyAsset.assetId,
            brokerUrl,
        });
        // Subtract the BaaS fee to end up at the final displayed commissionBps
        let serviceCommission = parseInt(tradeQuote.affiliateBps) - constants_1.CHAINFLIP_BAAS_COMMISSION;
        if (serviceCommission < 0)
            serviceCommission = 0;
        const minimumPrice = (0, helpers_1.calculateChainflipMinPrice)({
            slippageTolerancePercentageDecimal: tradeQuote.slippageTolerancePercentageDecimal,
            sellAsset: step.sellAsset,
            buyAsset: step.buyAsset,
            buyAmountAfterFeesCryptoBaseUnit: step.buyAmountAfterFeesCryptoBaseUnit,
            sellAmountIncludingProtocolFeesCryptoBaseUnit: step.sellAmountIncludingProtocolFeesCryptoBaseUnit,
        });
        const adapter = assertGetUtxoChainAdapter(step.sellAsset.chainId);
        const maybeSwapResponse = await (0, helpers_1.getChainFlipSwap)({
            brokerUrl,
            apiKey,
            sourceAsset,
            destinationAsset,
            destinationAddress: tradeQuote.receiveAddress,
            minimumPrice,
            refundAddress: senderAddress,
            commissionBps: serviceCommission,
            boostFee: step.source === constants_1.CHAINFLIP_BOOST_SWAP_SOURCE ? 10 : 0,
        });
        if (maybeSwapResponse.isErr()) {
            const error = maybeSwapResponse.unwrapErr();
            const cause = error.cause;
            throw Error(cause.response.data.detail);
        }
        const { data: swapResponse } = maybeSwapResponse.unwrap();
        tradeQuoteMetadata.set(tradeQuote.id, swapResponse);
        const depositAddress = swapResponse.address;
        return adapter.buildSendApiTransaction({
            value: step.sellAmountIncludingProtocolFeesCryptoBaseUnit,
            xpub: xpub,
            to: depositAddress,
            accountNumber: step.accountNumber,
            skipToAddressValidation: true,
            chainSpecific: {
                accountType,
                satoshiPerByte: step.feeData.chainSpecific.satsPerByte,
            },
        });
    },
    getUnsignedSolanaTransaction: async ({ tradeQuote, from, assertGetSolanaChainAdapter, config, }) => {
        if (!(0, utils_1.isExecutableTradeQuote)(tradeQuote))
            throw Error('Unable to execute trade');
        const brokerUrl = config.REACT_APP_CHAINFLIP_API_URL;
        const apiKey = config.REACT_APP_CHAINFLIP_API_KEY;
        const step = tradeQuote.steps[0];
        if (!(0, utils_1.isExecutableTradeStep)(step))
            throw Error('Unable to execute step');
        const sourceAsset = await (0, helpers_1.getChainFlipIdFromAssetId)({
            assetId: step.sellAsset.assetId,
            brokerUrl,
        });
        const destinationAsset = await (0, helpers_1.getChainFlipIdFromAssetId)({
            assetId: step.buyAsset.assetId,
            brokerUrl,
        });
        // Subtract the BaaS fee to end up at the final displayed commissionBps
        let serviceCommission = parseInt(tradeQuote.affiliateBps) - constants_1.CHAINFLIP_BAAS_COMMISSION;
        if (serviceCommission < 0)
            serviceCommission = 0;
        const minimumPrice = (0, helpers_1.calculateChainflipMinPrice)({
            slippageTolerancePercentageDecimal: tradeQuote.slippageTolerancePercentageDecimal,
            sellAsset: step.sellAsset,
            buyAsset: step.buyAsset,
            buyAmountAfterFeesCryptoBaseUnit: step.buyAmountAfterFeesCryptoBaseUnit,
            sellAmountIncludingProtocolFeesCryptoBaseUnit: step.sellAmountIncludingProtocolFeesCryptoBaseUnit,
        });
        const maybeSwapResponse = await (0, helpers_1.getChainFlipSwap)({
            brokerUrl,
            apiKey,
            sourceAsset,
            destinationAsset,
            destinationAddress: tradeQuote.receiveAddress,
            minimumPrice,
            refundAddress: from,
            commissionBps: serviceCommission,
            boostFee: 0,
        });
        if (maybeSwapResponse.isErr()) {
            const error = maybeSwapResponse.unwrapErr();
            const cause = error.cause;
            throw Error(cause.response.data.detail);
        }
        const { data: swapResponse } = maybeSwapResponse.unwrap();
        tradeQuoteMetadata.set(tradeQuote.id, swapResponse);
        const adapter = assertGetSolanaChainAdapter(step.sellAsset.chainId);
        const contractAddress = step.sellAsset.assetId === caip_1.solAssetId
            ? undefined
            : (0, caip_1.fromAssetId)(step.sellAsset.assetId).assetReference;
        const depositAddress = swapResponse.address;
        const getFeeDataInput = {
            to: depositAddress,
            value: step.sellAmountIncludingProtocolFeesCryptoBaseUnit,
            chainSpecific: {
                from,
                tokenId: contractAddress,
            },
        };
        const { fast } = await adapter.getFeeData(getFeeDataInput);
        const buildSendTxInput = {
            to: depositAddress,
            from,
            value: step.sellAmountIncludingProtocolFeesCryptoBaseUnit,
            accountNumber: step.accountNumber,
            chainSpecific: {
                tokenId: contractAddress,
                computeUnitLimit: fast.chainSpecific.computeUnits,
                computeUnitPrice: fast.chainSpecific.priorityFee,
            },
        };
        return (await adapter.buildSendApiTransaction(buildSendTxInput)).txToSign;
    },
    checkTradeStatus: async ({ config, quoteId, }) => {
        const swap = tradeQuoteMetadata.get(quoteId);
        if (!swap)
            throw Error(`missing trade quote metadata for quoteId ${quoteId}`);
        // Note, the swapId isn't the quoteId - we set the swapId at pre-execution time, when getting the receive addy and instantiating a flip swap
        const swapId = swap.id;
        const brokerUrl = config.REACT_APP_CHAINFLIP_API_URL;
        const apiKey = config.REACT_APP_CHAINFLIP_API_KEY;
        const maybeStatusResponse = await chainflipService_1.chainflipService.get(`${brokerUrl}/status-by-id?apiKey=${apiKey}&swapId=${swapId}`);
        if (maybeStatusResponse.isErr()) {
            return {
                buyTxHash: undefined,
                status: unchained_client_1.TxStatus.Unknown,
                message: undefined,
            };
        }
        const { data: statusResponse } = maybeStatusResponse.unwrap();
        const { status: { swapEgress }, } = statusResponse;
        // Assume no outbound Tx is a pending Tx
        if (!swapEgress?.transactionReference) {
            return {
                buyTxHash: undefined,
                status: unchained_client_1.TxStatus.Pending,
                message: (0, getLatestChainflipStatusMessage_1.getLatestChainflipStatusMessage)(statusResponse),
            };
        }
        // Assume as soon as we have an outbound Tx, the swap is complete.
        // Chainflip waits for 3 confirmations to assume complete (vs. 1 for us), which is turbo long.
        return {
            buyTxHash: swapEgress.transactionReference,
            status: unchained_client_1.TxStatus.Confirmed,
            message: undefined,
        };
    },
};
