"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getL1Rate = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const uuid_1 = require("uuid");
const __1 = require("../../..");
const types_1 = require("../../../types");
const utils_2 = require("../../../utils");
const constants_1 = require("../constants");
const getThorTxData_1 = require("../evm/utils/getThorTxData");
const getThorTxData_2 = require("../utxo/utils/getThorTxData");
const constants_2 = require("./constants");
const getQuote_1 = require("./getQuote/getQuote");
const longTailHelpers_1 = require("./longTailHelpers");
const getEvmTxFees_1 = require("./txFeeHelpers/evmTxFees/getEvmTxFees");
const getUtxoTxFees_1 = require("./txFeeHelpers/utxoTxFees/getUtxoTxFees");
const getL1Rate = async (input, deps, streamingInterval, tradeType) => {
    const { sellAsset, buyAsset, sellAmountIncludingProtocolFeesCryptoBaseUnit: sellAmountCryptoBaseUnit, accountNumber, receiveAddress, affiliateBps: requestedAffiliateBps, potentialAffiliateBps, } = input;
    const { chainNamespace } = (0, caip_1.fromAssetId)(sellAsset.assetId);
    if (chainNamespace === caip_1.CHAIN_NAMESPACE.Solana) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'Solana is not supported',
            code: types_1.TradeQuoteError.UnsupportedTradePair,
        }));
    }
    const slippageTolerancePercentageDecimal = input.slippageTolerancePercentageDecimal ??
        (0, __1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.Thorchain);
    const inputSlippageBps = (0, utils_1.convertDecimalPercentageToBasisPoints)(slippageTolerancePercentageDecimal);
    const maybeSwapQuote = await (0, getQuote_1.getQuote)({
        sellAsset,
        buyAssetId: buyAsset.assetId,
        sellAmountCryptoBaseUnit,
        receiveAddress,
        streaming: false,
        affiliateBps: requestedAffiliateBps,
    }, deps);
    if (maybeSwapQuote.isErr())
        return (0, monads_1.Err)(maybeSwapQuote.unwrapErr());
    const swapQuote = maybeSwapQuote.unwrap();
    const maybeStreamingSwapQuote = deps.config.REACT_APP_FEATURE_THOR_SWAP_STREAMING_SWAPS
        ? await (0, getQuote_1.getQuote)({
            sellAsset,
            buyAssetId: buyAsset.assetId,
            sellAmountCryptoBaseUnit,
            receiveAddress: undefined,
            streaming: true,
            affiliateBps: requestedAffiliateBps,
            streamingInterval,
        }, deps)
        : undefined;
    if (maybeStreamingSwapQuote?.isErr())
        return (0, monads_1.Err)(maybeStreamingSwapQuote.unwrapErr());
    const streamingSwapQuote = maybeStreamingSwapQuote?.unwrap();
    // recommended_min_amount_in should be the same value for both types of swaps
    const recommendedMinimumCryptoBaseUnit = swapQuote.recommended_min_amount_in
        ? (0, utils_1.convertPrecision)({
            value: swapQuote.recommended_min_amount_in,
            inputExponent: constants_2.THORCHAIN_FIXED_PRECISION,
            outputExponent: sellAsset.precision,
        }).toFixed()
        : '0';
    const getRouteValues = (quote, isStreaming) => {
        const source = (() => {
            if (isStreaming && tradeType === longTailHelpers_1.TradeType.L1ToL1)
                return constants_1.THORCHAIN_STREAM_SWAP_SOURCE;
            if (isStreaming &&
                [longTailHelpers_1.TradeType.L1ToLongTail, longTailHelpers_1.TradeType.LongTailToL1, longTailHelpers_1.TradeType.LongTailToLongTail].includes(tradeType))
                return constants_1.THORCHAIN_LONGTAIL_STREAMING_SWAP_SOURCE;
            if (!isStreaming &&
                [longTailHelpers_1.TradeType.L1ToLongTail, longTailHelpers_1.TradeType.LongTailToL1, longTailHelpers_1.TradeType.LongTailToLongTail].includes(tradeType))
                return constants_1.THORCHAIN_LONGTAIL_SWAP_SOURCE;
            return types_1.SwapperName.Thorchain;
        })();
        return {
            source,
            quote,
            expectedAmountOutThorBaseUnit: (0, utils_1.bnOrZero)(quote.expected_amount_out).toFixed(),
            isStreaming,
            affiliateBps: quote.fees.affiliate === '0' ? '0' : requestedAffiliateBps,
            // always use TC auto stream quote (0 limit = 5bps - 50bps, sometimes up to 100bps)
            // see: https://discord.com/channels/838986635756044328/1166265575941619742/1166500062101250100
            slippageBps: isStreaming ? (0, utils_1.bn)(0) : inputSlippageBps,
            estimatedExecutionTimeMs: quote.total_swap_seconds
                ? 1000 * quote.total_swap_seconds
                : undefined,
        };
    };
    const perRouteValues = [getRouteValues(swapQuote, false)];
    if (streamingSwapQuote &&
        swapQuote.expected_amount_out !== streamingSwapQuote.expected_amount_out) {
        perRouteValues.push(getRouteValues(streamingSwapQuote, true));
    }
    const getRouteRate = (expectedAmountOutThorBaseUnit) => {
        const sellAmountCryptoPrecision = (0, utils_1.fromBaseUnit)(sellAmountCryptoBaseUnit, sellAsset.precision);
        // All thorchain pool amounts are base 8 regardless of token precision
        const sellAmountCryptoThorBaseUnit = (0, utils_1.bn)((0, utils_1.toBaseUnit)(sellAmountCryptoPrecision, constants_1.THOR_PRECISION));
        return (0, utils_1.bnOrZero)(expectedAmountOutThorBaseUnit).div(sellAmountCryptoThorBaseUnit).toFixed();
    };
    const getRouteBuyAmountBeforeFeesCryptoBaseUnit = (quote) => {
        const buyAmountBeforeFeesCryptoThorPrecision = (0, utils_1.bn)(quote.expected_amount_out).plus(quote.fees.total);
        return (0, utils_1.toBaseUnit)((0, utils_1.fromBaseUnit)(buyAmountBeforeFeesCryptoThorPrecision, constants_2.THORCHAIN_FIXED_PRECISION), buyAsset.precision);
    };
    const getProtocolFees = (quote) => {
        // THORChain fees consist of liquidity, outbound, and affiliate fees
        // For the purpose of displaying protocol fees to the user, we don't need the latter
        // The reason for that is the affiliate fee is shown as its own "ShapeShift fee" section
        // Including the affiliate fee here would result in the protocol fee being wrong, as affiliate fees would be
        // double accounted for both in protocol fees, and affiliate fee
        const buyAssetTradeFeeBuyAssetCryptoThorPrecision = (0, utils_1.bnOrZero)(quote.fees.total).minus(quote.fees.affiliate);
        const buyAssetTradeFeeBuyAssetCryptoBaseUnit = (0, utils_1.convertPrecision)({
            value: buyAssetTradeFeeBuyAssetCryptoThorPrecision,
            inputExponent: constants_2.THORCHAIN_FIXED_PRECISION,
            outputExponent: buyAsset.precision,
        });
        const protocolFees = {};
        if (!buyAssetTradeFeeBuyAssetCryptoBaseUnit.isZero()) {
            protocolFees[buyAsset.assetId] = {
                amountCryptoBaseUnit: buyAssetTradeFeeBuyAssetCryptoBaseUnit.toString(),
                requiresBalance: false,
                asset: buyAsset,
            };
        }
        return protocolFees;
    };
    switch (chainNamespace) {
        case caip_1.CHAIN_NAMESPACE.Evm: {
            const sellAdapter = deps.assertGetEvmChainAdapter(sellAsset.chainId);
            const { networkFeeCryptoBaseUnit } = await (0, getEvmTxFees_1.getEvmTxFees)({
                adapter: sellAdapter,
                supportsEIP1559: Boolean(input.supportsEIP1559),
            });
            const maybeRoutes = await Promise.allSettled(perRouteValues.map(async ({ source, quote, expectedAmountOutThorBaseUnit, isStreaming, estimatedExecutionTimeMs, affiliateBps, }) => {
                const rate = getRouteRate(expectedAmountOutThorBaseUnit);
                const buyAmountBeforeFeesCryptoBaseUnit = getRouteBuyAmountBeforeFeesCryptoBaseUnit(quote);
                // No memo returned for rates
                const memo = '';
                const { data, router, vault } = await (0, getThorTxData_1.getThorTxInfo)({
                    sellAsset,
                    sellAmountCryptoBaseUnit,
                    memo,
                    expiry: quote.expiry,
                    config: deps.config,
                });
                const buyAmountAfterFeesCryptoBaseUnit = (0, utils_1.convertPrecision)({
                    value: expectedAmountOutThorBaseUnit,
                    inputExponent: constants_2.THORCHAIN_FIXED_PRECISION,
                    outputExponent: buyAsset.precision,
                }).toFixed();
                return {
                    id: (0, uuid_1.v4)(),
                    accountNumber: undefined,
                    memo,
                    receiveAddress: undefined,
                    affiliateBps,
                    potentialAffiliateBps,
                    isStreaming,
                    recommendedMinimumCryptoBaseUnit,
                    slippageTolerancePercentageDecimal: isStreaming
                        ? undefined
                        : slippageTolerancePercentageDecimal,
                    rate,
                    data,
                    router,
                    vault,
                    expiry: quote.expiry,
                    tradeType: tradeType ?? longTailHelpers_1.TradeType.L1ToL1,
                    steps: [
                        {
                            estimatedExecutionTimeMs,
                            rate,
                            sellAmountIncludingProtocolFeesCryptoBaseUnit: sellAmountCryptoBaseUnit,
                            buyAmountBeforeFeesCryptoBaseUnit,
                            buyAmountAfterFeesCryptoBaseUnit,
                            source,
                            buyAsset,
                            sellAsset,
                            accountNumber: accountNumber,
                            allowanceContract: router,
                            feeData: {
                                networkFeeCryptoBaseUnit,
                                protocolFees: getProtocolFees(quote),
                            },
                        },
                    ],
                };
            }));
            const routes = maybeRoutes.filter(utils_1.isFulfilled).map(maybeRoute => maybeRoute.value);
            // if no routes succeeded, return failure from swapper
            if (!routes.length)
                return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
                    message: 'Unable to create any routes',
                    code: types_1.TradeQuoteError.UnsupportedTradePair,
                    cause: maybeRoutes.filter(utils_1.isRejected).map(maybeRoute => maybeRoute.reason),
                }));
            // otherwise, return all that succeeded
            return (0, monads_1.Ok)(routes);
        }
        case caip_1.CHAIN_NAMESPACE.Utxo: {
            const maybeRoutes = await Promise.allSettled(perRouteValues.map(async ({ source, quote, expectedAmountOutThorBaseUnit, isStreaming, estimatedExecutionTimeMs, affiliateBps, }) => {
                const rate = getRouteRate(expectedAmountOutThorBaseUnit);
                const buyAmountBeforeFeesCryptoBaseUnit = getRouteBuyAmountBeforeFeesCryptoBaseUnit(quote);
                // No memo for trade rates
                const memo = '';
                const feeData = await (async () => {
                    // This is a rate without a wallet connected, so we can't get fees
                    if (!input.xpub)
                        return {
                            networkFeeCryptoBaseUnit: undefined,
                            protocolFees: getProtocolFees(quote),
                        };
                    // This works by leveraging the xpub if it exists. Even though this is a rate, we absolutely can (and will if possible) pass a xpub
                    // However, we run the risk that https://github.com/shapeshift/web/issues/7979 breaks this, since connecting a wallet won't refetch a quote
                    // If that becomes an issue we should be able to get a very rough estimation (not taking users' UTXOs into account) without an address
                    // using sats per byte and byte size from memo. Yes, we don't have a memo returned, but can build it in-house for this purpose easily.
                    const { vault, opReturnData, pubkey } = await (0, getThorTxData_2.getThorTxInfo)({
                        sellAsset,
                        xpub: input.xpub,
                        memo,
                        config: deps.config,
                    });
                    const sellAdapter = deps.assertGetUtxoChainAdapter(sellAsset.chainId);
                    return (0, getUtxoTxFees_1.getUtxoTxFees)({
                        sellAmountCryptoBaseUnit,
                        vault,
                        opReturnData,
                        pubkey,
                        sellAdapter,
                        protocolFees: getProtocolFees(quote),
                    });
                })();
                const buyAmountAfterFeesCryptoBaseUnit = (0, utils_1.convertPrecision)({
                    value: expectedAmountOutThorBaseUnit,
                    inputExponent: constants_2.THORCHAIN_FIXED_PRECISION,
                    outputExponent: buyAsset.precision,
                }).toFixed();
                return {
                    id: (0, uuid_1.v4)(),
                    accountNumber: undefined,
                    memo,
                    receiveAddress: undefined,
                    affiliateBps,
                    potentialAffiliateBps,
                    isStreaming,
                    recommendedMinimumCryptoBaseUnit,
                    tradeType: tradeType ?? longTailHelpers_1.TradeType.L1ToL1,
                    expiry: quote.expiry,
                    slippageTolerancePercentageDecimal: isStreaming
                        ? undefined
                        : slippageTolerancePercentageDecimal,
                    rate,
                    steps: [
                        {
                            estimatedExecutionTimeMs,
                            rate,
                            sellAmountIncludingProtocolFeesCryptoBaseUnit: sellAmountCryptoBaseUnit,
                            buyAmountBeforeFeesCryptoBaseUnit,
                            buyAmountAfterFeesCryptoBaseUnit,
                            source,
                            buyAsset,
                            sellAsset,
                            // TODO(gomes): when we actually split between TradeQuote and TradeRate in https://github.com/shapeshift/web/issues/7941,
                            // this won't be an issue anymore - for now this is tackled at runtime with the isConnected check above
                            accountNumber: accountNumber,
                            allowanceContract: '0x0',
                            feeData,
                        },
                    ],
                };
            }));
            const routes = maybeRoutes.filter(utils_1.isFulfilled).map(maybeRoute => maybeRoute.value);
            // if no routes succeeded, return failure from swapper
            if (!routes.length)
                return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
                    message: 'Unable to create any routes',
                    code: types_1.TradeQuoteError.UnsupportedTradePair,
                    cause: maybeRoutes.filter(utils_1.isRejected).map(maybeRoute => maybeRoute.reason),
                }));
            // otherwise, return all that succeeded
            return (0, monads_1.Ok)(routes);
        }
        case caip_1.CHAIN_NAMESPACE.CosmosSdk: {
            const cosmosChainAdapter = deps.assertGetCosmosSdkChainAdapter(sellAsset.chainId);
            const feeData = await cosmosChainAdapter.getFeeData({});
            return (0, monads_1.Ok)(perRouteValues.map(({ source, quote, expectedAmountOutThorBaseUnit, isStreaming, estimatedExecutionTimeMs, affiliateBps, }) => {
                const rate = getRouteRate(expectedAmountOutThorBaseUnit);
                const buyAmountBeforeFeesCryptoBaseUnit = getRouteBuyAmountBeforeFeesCryptoBaseUnit(quote);
                const buyAmountAfterFeesCryptoBaseUnit = (0, utils_1.convertPrecision)({
                    value: expectedAmountOutThorBaseUnit,
                    inputExponent: constants_2.THORCHAIN_FIXED_PRECISION,
                    outputExponent: buyAsset.precision,
                }).toFixed();
                // No memo returned for rates
                const memo = '';
                return {
                    id: (0, uuid_1.v4)(),
                    accountNumber: undefined,
                    memo,
                    receiveAddress: undefined,
                    affiliateBps,
                    potentialAffiliateBps,
                    isStreaming,
                    recommendedMinimumCryptoBaseUnit,
                    expiry: quote.expiry,
                    slippageTolerancePercentageDecimal: isStreaming
                        ? undefined
                        : slippageTolerancePercentageDecimal,
                    rate,
                    tradeType: tradeType ?? longTailHelpers_1.TradeType.L1ToL1,
                    steps: [
                        {
                            estimatedExecutionTimeMs,
                            rate,
                            sellAmountIncludingProtocolFeesCryptoBaseUnit: sellAmountCryptoBaseUnit,
                            buyAmountBeforeFeesCryptoBaseUnit,
                            buyAmountAfterFeesCryptoBaseUnit,
                            source,
                            buyAsset,
                            sellAsset,
                            accountNumber,
                            allowanceContract: '0x0',
                            feeData: {
                                networkFeeCryptoBaseUnit: feeData.fast.txFee,
                                protocolFees: getProtocolFees(quote),
                                chainSpecific: {
                                    estimatedGasCryptoBaseUnit: feeData.fast.chainSpecific.gasLimit,
                                },
                            },
                        },
                    ],
                };
            }));
        }
        default:
            (0, utils_1.assertUnreachable)(chainNamespace);
    }
};
exports.getL1Rate = getL1Rate;
